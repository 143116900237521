import React from 'react'
import { Formik } from 'formik'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormGroup from 'react-bootstrap/FormGroup'

import CardListField from './input/CardListField'
import InputField from './input/InputField'
import { useNavigate } from 'react-router-dom'
import { condense } from '../util'

export interface CreateAndJoinRoomInput {
    nickname: string
    roomName: string
    cards: string[]
}

export interface CreateAndJoinRoomResult {
    roomCode: string
    ownerToken: string
}

export interface CreateAndJoinRoomError {
    status: string
    fields: Record<string, string>
}

const PRESETS = [
    {
        name: 'Test',
        cards: '1,2,4,6,8,12'.split(','),
    },
]

const CreateRoom: React.FC = () => {
    const navigate = useNavigate()

    return (
        <Formik<CreateAndJoinRoomInput>
            initialValues={{
                nickname: '',
                roomName: '',
                cards: [],
            }}
            validate={({ nickname, roomName, cards }) => condense({
                nickname: !nickname.trim() && 'Nickname is required',
                roomName: !roomName.trim() && 'Room name is required',
                cards: cards.length < 2 && 'Must have at least 2 cards',
            })}
            onSubmit={async ({ nickname, roomName, cards }, helpers) => {
                try {
                    console.log("Submitting")
                    const response = await fetch(process.env.REACT_APP_API_ROOT + 'api/room', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ roomName, cards }),
                    })
                    if (response.ok) {
                        const result: CreateAndJoinRoomResult =
                            await response.json()
                        navigate(`/room/${result.roomCode}`, {
                            state: {
                                ownerToken: result.ownerToken,
                                autoJoinNickname: nickname,
                            }
                        })
                    } else {

                        const error: CreateAndJoinRoomError = await response.json()
                        if (error.fields) {
                            helpers.setStatus(error.status)
                            helpers.setErrors(error.fields)
                        } else {
                            helpers.setStatus(`Error submitting form: ${response.status}: ${response.statusText}`)
                        }
                    }
                } catch (e) {
                    helpers.setStatus(`Error submitting form: ${(e as Error).message}`)
                }
            }}
        >
            {({ handleReset, handleSubmit, isSubmitting, status }) => {
                return (
                    <Form
                        onReset={handleReset}
                        onSubmit={handleSubmit}
                        className="my-5"
                    >
                        <h3 className="text-center">Create Room</h3>
                        <FormGroup className="mb-3">
                            <InputField
                                name="roomName"
                                label="Room Name"
                                required
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <CardListField name="cards" presets={PRESETS} />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <InputField
                                name="nickname"
                                label="Nickname"
                                required
                            />
                        </FormGroup>
                        {status && <Alert variant='danger'>{status}</Alert>}
                        <div className="d-flex justify-content-start">
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={isSubmitting}
                            >
                                Create and Join
                            </Button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default CreateRoom
