import React, { useEffect, useReducer, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import Container from 'react-bootstrap/Container'
import Loader from './components/Loader/Loader'
import NicknameForm from './components/NicknameForm/NicknameForm'
// import CardStack from './components/CardStack/CardStack'

interface Room {
    name: string
    cards: string[]
    players: Player[]
    votes: Map<string, string>
}

interface Player {
    nickname: string
    isOwner: boolean
    hasVoted: boolean
}

interface RoomLocation {
    state: {
        ownerToken?: string
        autoJoinNickname?: string
    } | null
}

function urlForRoom(roomId: string) {
    const rootUrl = new URL(process.env.REACT_APP_API_ROOT || window.location.origin)
    rootUrl.protocol = rootUrl.protocol === "https:" ? "wss" : "ws"
    return `${rootUrl.href}api/room/${roomId}/ws`
}

function useRoomParams() {
    const { roomId } = useParams()
    const socketUrl = urlForRoom(roomId!)

    const { state } = useLocation() as RoomLocation
    const { ownerToken, autoJoinNickname } = state || {}

    const [searchParams] = useSearchParams()
    const prefillNickname = searchParams.get('nickname') || localStorage.getItem('nickname')

    return { roomId, ownerToken, autoJoinNickname, prefillNickname, socketUrl }
}

const Room: React.FC = () => {
    const { roomId, ownerToken, autoJoinNickname, prefillNickname, socketUrl } = useRoomParams()
    const [customState, setCustomState] = useState('')
    const [joined, setJoined] = useState(false)
    const [joining, setJoining] = useState(false)

    const { sendMessage, readyState } = useWebSocket(socketUrl, {
        onMessage(event) {
            console.log(event)
        },
        onOpen(event) {
            console.log(event)
            if (autoJoinNickname) {
                setCustomState('Joining...')
                setJoining(true)
                sendMessage(JSON.stringify({
                    type: 'join',
                    nickname: autoJoinNickname,
                    ownerToken
                }))
            }
        }
    })

    return (
        <Container className="my-1">
            <h3 className="d-flex align-items-end gap-2 mb-3">
                <img src="/img/poke.svg" style={{ height: '1.5em' }} />
                Poke
            </h3>
            <Loader readyState={readyState} customState={customState}>
                <>
                    {!joined && <NicknameForm nickname={prefillNickname || ''} onSubmit={({ nickname }) => {
                        sendMessage(JSON.stringify({ type: 'join', nickname, ownerToken }))
                    }}></NicknameForm>}
                </>
            </Loader>
        </Container>
    )
}

export default Room
