import React, { ReactNode } from 'react'
import { ReadyState } from 'react-use-websocket'

import styles from './Loader.module.scss'

interface Props {
    children: React.ReactElement
    readyState: ReadyState
    customState: string
}

const Loader: React.FC<Props> = ({ children, readyState, customState }) => {

    switch (readyState) {
        case ReadyState.CLOSED:
            return <div className={styles.Loader}>Closed</div>
        case ReadyState.CLOSING:
            return <div className={styles.Loader}>Disconnecting...</div>
        case ReadyState.CONNECTING:
            return <div className={styles.Loader}>Connecting...</div>
        case ReadyState.OPEN:
            if(customState) return <div className={styles.Loader}>{customState}</div>
            else return children
        case ReadyState.UNINSTANTIATED:
            return <div className={styles.Loader}>UNINSTANTIATED</div>
    }
}

export default Loader
