import React, { ReactNode, useEffect, useRef, useState } from 'react'
import FormControl from 'react-bootstrap/FormControl'
import FormSelect from 'react-bootstrap/FormSelect'
import FormLabel from 'react-bootstrap/FormLabel'
import Feedback from 'react-bootstrap/Feedback'

import { Field, useField } from 'formik'

import { cx, nanoid } from '../../util'

export interface CardPreset {
    name: string
    cards: string[]
}

interface Props {
    name: string
    presets: CardPreset[]
}

function matchCards(a: string[], b: string[]): boolean {
    return a.length == b.length && a.every((v, i) => b[i] == v)
}

function findPreset(
    cards: string[],
    presets: CardPreset[]
): string | undefined {
    const preset = presets.find((p) => matchCards(cards, p.cards))
    return preset ? preset.name : undefined
}

function splitCards(cards: string): string[] {
    return cards
        .split(',')
        .map((c) => c.trim())
        .filter((x) => x)
}

function joinCards(cards: string[]): string {
    return cards.join(', ')
}

const CardListField: React.FC<Props> = ({ name, presets }) => {
    const [field, meta, helper] = useField(name)
    const [id] = useState(nanoid)
    const [feedbackId] = useState(nanoid)

    const [selectedPreset, setSelectedPreset] = useState(() =>
        findPreset(field.value, presets)
    )
    const [customValue, setCustomValue] = useState<string>(() =>
        joinCards(field.value)
    )
    const customRef = useRef<HTMLInputElement>(null)

    const isInvalid = meta.touched && !!meta.error

    const showCustom = selectedPreset == 'custom'

    useEffect(() => {
        if (showCustom && !matchCards(splitCards(customValue), field.value)) {
            setCustomValue(joinCards(field.value))
        }
    }, [showCustom, customValue, field.value])

    return (
        <>
            <FormLabel htmlFor={id}>Cards</FormLabel>

            <FormSelect
                className={cx({ 'mb-3': showCustom })}
                value={selectedPreset}
                isInvalid={isInvalid}
                onChange={(e) => {
                    const value = e.target.value
                    setSelectedPreset(value)
                    if (value == 'custom') {
                        helper.setValue(splitCards(customValue))
                        setTimeout(() => customRef.current?.focus(), 0)
                    } else {
                        const preset = presets.find((p) => p.name == value)
                        helper.setValue(preset ? preset.cards : [])
                    }
                }}
            >
                <option value=""></option>
                {presets.map((v) => (
                    <option key={v.name} value={v.name}>
                        {v.name}: {v.cards.join(', ')}
                    </option>
                ))}
                <option value="custom">Custom</option>
            </FormSelect>

            {showCustom && (
                <FormControl
                    ref={customRef}
                    isInvalid={isInvalid}
                    value={customValue}
                    onChange={(e) => {
                        const value = e.target.value
                        setCustomValue(value)
                        const newCards = splitCards(value)
                        if (!matchCards(newCards, field.value)) {
                            helper.setValue(newCards)
                        }
                    }}
                    onBlur={() => helper.setTouched(true)}
                    {...(isInvalid && { 'aria-describedby': feedbackId })}
                />
            )}

            {isInvalid && (
                <Feedback id={feedbackId} type="invalid">
                    {meta.error}
                </Feedback>
            )}
        </>
    )
}

export default CardListField
