import React, { ReactNode, useState } from 'react'
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl'
import FormLabel from 'react-bootstrap/FormLabel'
import Feedback from 'react-bootstrap/Feedback'

import { useField } from 'formik'

import { nanoid } from '../../util'

interface Props extends FormControlProps {
    name: string
    label?: string | ReactNode
    // For some reason FormControlProps doesn't like the `required` property but
    // FormControl is fine with it, so I guess we'll just pass it along manually
    required?: boolean
}

const InputField: React.FC<Props> = ({ label, required, ...props }) => {
    const [field, meta] = useField(props.name)
    const [id] = useState(() => props.id || nanoid())
    const [feedbackId] = useState(nanoid)

    const isInvalid = meta.touched && !!meta.error

    return (
        <>
            {typeof label === 'string' ? (
                <FormLabel htmlFor={id}>{label}</FormLabel>
            ) : (
                label
            )}
            <FormControl
                isInvalid={isInvalid}
                required={required}
                {...field}
                {...props}
                id={id}
                {...(isInvalid && { 'aria-describedby': feedbackId })}
            />
            {isInvalid && (
                <Feedback id={feedbackId} type="invalid">
                    {meta.error}
                </Feedback>
            )}
        </>
    )
}

export default InputField
