import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Home from '../pages/Home/Home'
import Room from '../pages/Room/Room'
import NotFound from '../pages/NotFound/NotFound'

const App: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/room/:roomId/:ownerToken" element={<Room />} />
            <Route path="/room/:roomId" element={<Room />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
    )
}

export default App
