import React from 'react'
import { Formik } from 'formik'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormGroup from 'react-bootstrap/FormGroup'

import InputField from './input/InputField'
import { useNavigate } from 'react-router-dom'

export interface JoinRoomInput {
    roomCode: string
    nickname: string
}

const JoinRoom: React.FC = () => {
    const navigate = useNavigate()

    return (
        <Formik<JoinRoomInput>
            initialValues={{
                roomCode: '',
                nickname: '',
            }}
            validate={({ roomCode, nickname }) => ({
                roomCode: !roomCode.trim() && 'Room Code is required',
                nickname: !nickname.trim() && 'nickname is required',
            })}
            onSubmit={({ roomCode, nickname }) => {
                navigate(`/room/${roomCode}`, {
                    state: {
                        autoJoinNickname: nickname,
                    }
                })
            }}
        >
            {({ handleReset, handleSubmit, isSubmitting }) => {
                return (
                    <Form
                        onReset={handleReset}
                        onSubmit={handleSubmit}
                        className="my-5"
                    >
                        <h3 className="text-center">Join Room</h3>
                        <FormGroup className="mb-3">
                            <InputField
                                name="roomCode"
                                label="Room Code"
                                required
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <InputField
                                name="nickname"
                                label="nickname"
                                required
                            />
                        </FormGroup>
                        <div className="d-flex justify-content-start">
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={isSubmitting}
                            >
                                Join
                            </Button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default JoinRoom
