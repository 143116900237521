import { Formik, FormikHelpers } from 'formik'
import React from 'react'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormGroup from 'react-bootstrap/FormGroup'

import InputField from '../../../../components/input/InputField'
import { condense } from '../../../../util'

interface NicknameInput {
    nickname: string
}

interface Props {
    nickname: string
    onSubmit: (values: NicknameInput, formikHelpers: FormikHelpers<NicknameInput>) => void | Promise<any>
}

const NicknameForm: React.FC<Props> = ({ nickname, onSubmit }) => {
    return (
        <Formik<NicknameInput>
            initialValues={{ nickname }}
            validate={({ nickname }) => condense({
                nickname: !nickname.trim() && 'nickname is required',
            })}
            onSubmit={onSubmit}
        >
            {({ handleReset, handleSubmit, isSubmitting }) => {
                return (
                    <Form
                        onReset={handleReset}
                        onSubmit={handleSubmit}
                        className="my-5"
                    >
                        <h3 className="text-center">Join Room</h3>
                        <FormGroup className="mb-3">
                            <InputField
                                name="nickname"
                                label="nickname"
                                required
                            />
                        </FormGroup>
                        <div className="d-flex justify-content-start">
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Joining...' : 'Join'}
                            </Button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default NicknameForm
