import React from 'react'

import Container from 'react-bootstrap/Container'

const NotFound: React.FC = () => {
    return (
        <Container className="my-1">
            <h3 className="d-flex align-items-end gap-2 mb-3">
                <img src="/img/poke.svg" style={{ height: '1.5em' }} />
                Poke
            </h3>
            404: Page Not Found
        </Container>
    )
}

export default NotFound
