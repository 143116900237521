import React from 'react'

import Container from 'react-bootstrap/Container'

import CreateRoom from '../../components/CreateRoom'
import JoinRoom from '../../components/JoinRoom'

export interface CreateAndJoinRoomInput {
    nickName: string
    roomName: string
    cards: string[]
}

const PRESETS = [
    {
        name: 'Test',
        cards: '1,2,4,6,8,12'.split(','),
    },
]

const Home: React.FC = () => {
    return (
        <Container className="my-5 max-w-xs">
            <h1 className="d-flex justify-content-center align-items-end gap-3 mb-3">
                <img src="/img/poke.svg" style={{ height: '2em' }} />
                Poke
            </h1>
            <CreateRoom />
            <JoinRoom />
        </Container>
    )
}

export default Home
