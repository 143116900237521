const chars = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz'
export function nanoid(size = 21): string {
    return crypto
        .getRandomValues(new Uint8Array(size))
        .reduce((pv, cv) => pv + chars[cv & 63], '')
}

export function cx(...list: Array<string | Record<string, boolean>>): string {
    return list
        .map((arg) => {
            return typeof arg === 'string'
                ? arg
                : Object.entries(arg)
                      .filter(([_, v]) => v)
                      .map(([k]) => k)
                      .join(' ')
        })
        .filter((x) => x)
        .join(' ')
}

type Without<T, U> = {
    [K in keyof T]: T[K] extends U ? never : T[K];
}

export function condense<T>(value: T): Without<T, undefined | null | false> {
    const entries = Object.entries(value)
    const filtered = entries.filter(([k, v]) => v !== false && v !== null && typeof v !== 'undefined')
    return Object.fromEntries(filtered) as Without<T, undefined | null | false>
}
